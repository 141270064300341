* {
    font-family: 'Poppins', sans-serif;
}

.navbar {
    background-color: #fff;
    position: fixed;
    z-index: 100000;
    margin: 0;
    padding: 0;
    filter: drop-shadow(0 0 0.13rem crimson);
    height: fit-content;
}

.container-fluid {
    width: 90%;
    position: relative;
}

.navbar-brand {
    color: #000;
    font-weight: bold;
    font-size: 24px;
}

.navbar-brand:hover {
    color: #000;
}

.navbar-nav {
    margin-left: 30px;
}

.navbar-nav .nav-item {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px 2px;
    text-decoration: none;
}

.navbar-nav .nav-item .nav-link {
    color: #000;
    position: relative;
    transition: 0.3s;
    font-weight: 400;
    font-size: 14px;
    padding: 10px;
}

.custom-dropdown-menu {
    display: none;
    position: fixed;
    background-color: white;
    margin-top: 14px;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 1;
    padding: 0;
    opacity: 0; /* Set initial opacity to 0 */
    transition: opacity 0.4s ease, display 0.3s ease;
}

.custom-dropdown-menu li a {
    color: black;
    width: 100px;
    padding: 12px 12px;
    text-decoration: none;
    display: block;
}

.custom-dropdown-menu-item {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.custom-dropdown-menu-item-left, .custom-dropdown-menu-item-middle, .custom-dropdown-menu-item-right {
    height: 100%;
    width: 100%;
    padding: 50px 30px 30px;
}

.custom-dropdown-menu-item-left {
    margin-left: 50px;
}

.dropdown-menu-item-text {
    width: 60%;
}

.dropdown-menu-item-btn {
    border: 1px solid white;
    display: inline-block;
    color: #fff;
    background-color: rgb(220, 20, 60);
    font-size: 0.7em;
    text-transform: uppercase;
    padding: 15px;
    width: 110px;
    text-align: center;
    -webkit-text-decoration: #fff;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    margin: 1%;
    transition: ease-in-out box-shadow .3s;
}
.dropdown-menu-item-btn:hover {
    opacity: 1;
    border: 1px solid rgba(220, 20, 60, 1.5);
    color: #fff;
    border-radius: 5px;
    text-decoration: none;
    transition: ease-in-out box-shadow .3s;
}

.dropdown-menu-item-btn:hover::before {
    filter: blur(10px);
    transition: ease-in-out filter .3s;
}

.dropdown-menu-item-context {
    list-style: none;
}

.dropdown-menu-item-context-item {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    padding: 10px;
    margin: 2px 0;
    transition: width ease-in-out .6s;
    position: relative; /* Added position relative */
}

.dropdown-menu-item-context-item .insights-link {
    width: fit-content;
    font-size: 14px;
    padding: 10px;
    margin: 0;
}

.dropdown-menu-item-context-item .fa-solid {
    position: absolute; /* Position absolute to stay on the right */
    right: 10px; /* Adjust according to your preference */
    /*width: 30px;*/
    padding: 10px;
    /*font-size: 16px;*/
}

.dropdown-menu-item-context-item:hover {
    filter: drop-shadow(0 0 4px rgba(220, 20, 60, .3));
    width: 85%;
    color: black;
    background-color: white;
    border-radius: 5px;
    transition: width ease-in-out .6s, filter ease-in-out .6s;
}

.dropdown:hover .custom-dropdown-menu {
    display: block;
    opacity: 1;
}

.dropdown>.dropdown-toggle:active {
    pointer-events: none;
}

.navbar-nav .nav-item .nav-link::before {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: crimson;
    transition: 0.3s;
    color: crimson;
}

.navbar-nav .nav-item .nav-link:hover::before {
    width: 100%;
    color: crimson;
}

.grad-bar {
    width: 100%;
    height: 5px;
    position: static;
    /*position: absolute;*/
    background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
    background-size: 400% 400%;
    -webkit-animation: gradbar 15s ease infinite;
    -moz-animation: gradbar 15s ease infinite;
    animation: gradbar 15s ease infinite;
}

/* KEYFRAME ANIMATIONS */

@-webkit-keyframes gradbar {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes gradbar {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}

@keyframes gradbar {
    0% {
        background-position: 0% 50%
    }
    50% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}


.slideshow {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding-top: 50px;
    /*background-color: #3B5998;*/
    /*background-color: rgba(255, 255, 255, 0);*/
}

.slideshow-image {
    /*width: 95%;*/
    height: 95%;
    object-fit: fill;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0);
    /*filter: grayscale(40%) blur(5px) contrast(80%);*/
    transition: transform 4s ease-in-out;
}

.slideshow-text {
    position: absolute;
    display: block;
    bottom: 100px;
    left: 40px;
    color: #000;
}

.slideshow-text-btn {
    border: 1px solid white;
    display: inline-block;
    color: #fff;
    background-color: rgb(220, 20, 60);
    font-size: 0.7em;
    text-transform: uppercase;
    /*margin: 10px 0;*/
    /*opacity: 0.65;*/
    padding: 15px;
    width: fit-content;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 1px;
    /*margin: 1% 46%;*/
    margin-left: 100px;
}

.slideshow-text-btn:hover {
    border-radius: 5px;
}

.slideshow-text-btn:hover a {
    color: white;
    text-decoration: none;
}

.heading {
    display: block;
    position: relative;
    /*height: 60%;*/
    width: 45%;
    font-size: 3em;
    bottom: 0;
    vertical-align: middle;
    /*line-height: 75px;*/
    /*margin-bottom: 0;*/
    /*background-color: #0077b5;*/
    color: white;
    padding: 0 0 0 100px;
}
.para-info {
    display: block;
    /*height: 200px;*/
    margin: 0;
    width: 40%;
    /*margin: 20px;*/
    line-height: 40px;
    font-size: 1.5rem;
    color: white;
    padding: 0 0 20px 100px;
    font-style: italic;
}

.headingShrink {
    font-size: 2rem;
    line-height: 0;
}

.bColor {
    color: crimson;
}

.slideshow-dots {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 30px 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slideshow-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    margin: 0 5px;
}

.slideshow-dot.active {
    background-color: crimson;
}

/*.slideshow-controls {*/
/*    position: absolute;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*}*/

/*.slideshow-controls button {*/
/*    background-color: black;*/
/*    color: white;*/
/*    padding: 10px;*/
/*    border-radius: 5px;*/
/*}*/

@media (max-width: 768px) {
    .slideshow-dots {
        display: none;
    }
}
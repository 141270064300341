.content {
    display: flex;
    flex-direction: column;
    /*position: absolute;*/
    height: 100%;
    width: 100%;
    max-width: 1920px;
    background-color: rgba(255, 255, 255, 0);
}

.section1, .section2, .section3, .section4, .section5 {
    display: block;
    position: relative;
    height: fit-content;
    width: 100vw;
    background-color: rgba(255, 255, 255, 0);
}

.section5 {
    height: 100vh;
    background-color: blue;
}

.comingsoon-words {
    display: block;
    position: absolute;
    /*background-color: #E73C7E;*/
    width: fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 65px;
    font-size: 2.2em;
    margin-left: -10px;
    font-weight: bold;
}
.social {
    position: fixed;
    top: 40%;
    left: 95%;
    width: fit-content;
    z-index: 1000000000000000;
}

.social .social-list {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    padding: 5px;
    background-color: rgb(0, 0, 0, 1);
    border-radius: 10px;
}

.social .social-list li {
    display: block;
    margin: 8px 5px;
    z-index: 1000000;
    width: 90px;
    border-radius: 30px;
    transition: all 1s;
}

.social .social-list li a {
    color: white;
    text-decoration: none;

}

.social .social-list li i {
    text-align: center;
    margin-left: 14px;
    color: black;
    background-color: white;
    padding: 15px;
    border-radius: 50%;
    font-size: 25px;
    transform: rotate(0deg);
}

.social .social-list li i:hover {
    border-radius: 5px;
    transition: 0.5s;
}

.social .social-list li:nth-child(1) i {
    color: rgba(18, 140, 126);
}

.social .social-list li:nth-child(1) i:hover {
    background-color: rgba(18, 140, 126);
    color: white;
}

/*.social .social-list li button {*/
/*    appearance: none;*/
/*    border: none;*/
/*    padding: 0;*/
/*    margin: 0;*/
/*    background: none;*/
/*    font: inherit;*/
/*    cursor: pointer;*/
/*    outline: none;*/
/*}*/

.social .social-list li:nth-child(2) i {
    color: rgb(10, 102, 194);
}

.social .social-list li:nth-child(2) i:hover {
    color: white;
    background-color: rgb(10, 102, 194);
}

.social .social-list li:nth-child(4) i:hover {
    color: white;
    background-color: rgba(91, 227, 180, 0.85);
}

.whatsappQR {
    display: none;
    position: absolute;
    height: 260px;
    width: 262px;
    margin-top: -60px;
    margin-left: 350px;
    transition: 3s ease-in;
}

.social .social-list li:nth-child(1) span:hover .whatsappQR {
    position: absolute;
    display: block;
    height: 220px;
    width: 222px;
    aspect-ratio: 1/4;
    border-radius: 10%;
    margin-top: -70px;
    margin-left: -250px;
    background-image: url('../../../public/qr_code.png');
    background-size: cover;
    background-repeat: no-repeat;
    transition: background-image 3s ease-in-out;
    filter: drop-shadow(0px 0px 8px #000);
}